import addSuppressedFlags from '@/utils/addSuppressedFlags';
import { list } from '@/services/firestore';
import { getSortConditionBySortOrder } from '@/pages/Monitoring/Risks/helpers';
// import { getSortConditionBySortOrder } from '@/pages/Risks/helpers';
import { pushParamsToUrl } from '@/utils/pushParamsToUrl';
import addCategoryToRisk from '@/utils/addCategoryToRisk';
import addSuppressedFlagsForRisks from '@/utils/addSuppressedFlagsForRisks';
import GLOBAL_META_CONFIG from '@/../config/AllServices';
import _ from 'lodash';
import moment from 'moment';
import { CNS_CATEGORIES } from './universalDrawer';
import { deletePreset, savePreset } from '@/services/filterPreset';
import { notification } from 'antd';
import { fetchCartographySignatures } from '@/services/cartographySignatures';

export const defaultRiskFilters = {
  tabName: 'Open Risks',
  searchValue: '',
  sortOrder: 'dsc',
  sortBy: 'Affected Resources',
  showSuppress: false,
  cloudProvider: [],
  severity: [],
  standards: [],
  service: [],
  securityGroups: [],
  categories: [],
  dataSource: [],
  // TimeDiscoveredTab: [],
};

export default {
  namespace: 'Risks',
  state: {
    resources: [],
    filteredResources: [],
    paginatedResources: [],
    counts: {
      totalResources: 0,
      totalRisks: 0,
      totalSuppressedRisks: 0,
      totalSuppressedResources: 0,
    },
    selectedDropdownFilter: 'cloud',
    selectedFiltersCategory: '',
    allTags: [],
    loading: false,
    allRisks: [], // all risks from resource report
    filteredRisks: [],
    risksLoader: false, // loader for open risks
    totalPages: 0,
    pageSize: 12,
    currentPage: 1,
    showSuppress: false,
    services: {},
    tags: [],
    risks: [],
    report: [],
    uniqueCves: [],
    uniqueSignaturesArray: [],
    standards: [],
    standardsWithSignatures: [],
    dataSourceCollection: [
      {
        name: 'Signatures',
        categories: [
          'authentications',
          'dataAtRisk',
          'iamMisconfigurations',
          'lateralMovement',
          'neglectedAssets',
          'vulnerabilities',
        ],
      },
      { name: 'Cloudxray', categories: ['osVulnerabilities', 'malware'] },
      // { name: 'Tenable', categories: ['tenable'] },
    ],
    categories: {},
    searchText: '',
    sortOrder: 'dsc',
    sortBy: 'Affected Resources',
    filtersApplied: {
      categories: [],
      cloudProvider: [],
      severity: [],
      standards: [],
      service: [],
      services: [],
      dataSource: [],
      timeDiscovered: [],
      securityGroups: [],
    },
    // this is dummy pagination - will be changed in future
    paginationCount: {
      startIndex: 0,
      endIndex: 12,
      current: 1,
    },
    tabName: 'Open Risks',
    presetModalLoading: false,
    presetModalState: false,
    cartographySignatures: {},
    cartographySignaturesLoading: false,
    selectedCartographyCloudAccount: {},
  },

  effects: {
    // This effect gets all signatures and cves from resource report of selected orgs
    *getRisks({}, { put, select }) {
      yield put({
        type: 'save',
        payload: {
          loading: true,
        },
      });
      try {
        // ? Check if current cloudAccount already have risks saved in the resourcesReport state?
        const cloudAccountsReports = yield select(
          state => state.resourcesReport.cloudAccountsReports
        );

        // const flattenData = data => {
        //   // Use lodash to map over the signatures object and create a new row for each entry
        //   return _.map(data.signatures, (signatureValue, signatureKey) => {
        //     // Create a new row combining resource-level data with signature data
        //     return {
        //       ..._.omit(data, ['signatures']), // Spread the top-level data, omitting the 'signatures' property
        //       signatureKey, // Add the signature key
        //       ...signatureValue, // Spread the signature object
        //       firstSeen: timestampToString(signatureValue.firstSeen),
        //       lastSeen: timestampToString(signatureValue.lastSeen),
        //     };
        //   });
        // };

        // // Helper function to convert Firestore timestamp to string
        // const timestampToString = timestamp => {
        //   const date = new Date(timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000);
        //   return moment(date).format('YYYY-MM-DD HH:mm:ss');
        // };

        // const exampleRisks = Object.values(cloudAccountsReports)[0].slice(0, 10);

        // let finalResultsForSigs = [];
        // _.forEach(exampleRisks, eachResource => {
        //   finalResultsForSigs = [...finalResultsForSigs, ...flattenData(eachResource)];
        // });

        // const downloadCSV = (csv, filename) => {
        //   const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        //   const url = URL.createObjectURL(blob);

        //   const a = document.createElement('a');
        //   a.href = url;
        //   a.download = filename;
        //   document.body.appendChild(a);
        //   a.click();

        //   // Cleanup
        //   document.body.removeChild(a);
        //   window.URL.revokeObjectURL(url);
        // };

        // console.log('finalResultsForSigs :>> ', finalResultsForSigs);

        // const fields = [
        //   'signatureKey',
        //   'status',
        //   'firstSeen',
        //   'lastSeen',
        //   {
        //     label: 'Resource Name',
        //     value: 'name',
        //   },
        //   'resourceId',
        //   'service',
        //   'provider',
        //   {
        //     label: 'Region',
        //     value: 'region',
        //   },
        //   {
        //     label: 'Cloud Account ID', // The column name in the CSV
        //     value: 'cloudAccountId', // The path to the data in your source objects
        //   },
        //   {
        //     label: 'Resource Created At',
        //     value: 'createdAt',
        //   },
        //   'creationOfRisk',
        //   {
        //     label: 'Instance State',
        //     value: 'instenceState',
        //   },
        //   'properties',
        //   'tags',
        //   'vpcId',
        // ]; // Custom columns

        // const opts = { fields };
        // const parser = new Parser(opts);
        // const csv = parser.parse(finalResultsForSigs);

        // downloadCSV(csv, 'risk_report.csv');

        const selectedCloudAccountIds = yield select(state =>
          state.cloudAccount.selectedCloudAccounts.map(eachCA => eachCA.id)
        );
        const { list: customSignaturesMetaData } = yield select(state => state.customSignatures);

        if (cloudAccountsReports || !selectedCloudAccountIds.length) {
          try {
            // * Taking out all the necessary states requried in this Function
            const organisation = yield select(state => state.organisation.current);
            const { signatures: signaturesMetaData } = yield select(
              state => state.signatureMetaData
            );
            const { globalCategories } = yield select(state => state.global);

            // ~~~~~~~~~~~~~ VARIABLES FOR STATE HANDLING ~~~~~~~~~~~~~
            let flattenedArrayOfRisks = [];
            let previousCloudAccountRisks = yield select(state => state.Risks.newData);

            if (_.isEmpty(previousCloudAccountRisks)) {
              // 1. Compute for all selected accounts risk newly
              const {
                previousCloudAccountRisks: updatedPreviousRisks,
                flattenedArrayOfRisks: finalRisks,
              } = processCloudAccounts(
                selectedCloudAccountIds,
                {},
                cloudAccountsReports,
                signaturesMetaData,
                customSignaturesMetaData,
                globalCategories
              );
              // Update your state or other variables as necessary
              flattenedArrayOfRisks = finalRisks;
              previousCloudAccountRisks = updatedPreviousRisks;
            } else {
              // 2. Process using existing risk data
              const {
                previousCloudAccountRisks: updatedPreviousRisks,
                flattenedArrayOfRisks: finalRisks,
              } = processCloudAccounts(
                selectedCloudAccountIds,
                previousCloudAccountRisks,
                cloudAccountsReports,
                signaturesMetaData,
                customSignaturesMetaData,
                globalCategories
              );
              // Update your state or other variables as necessary
              flattenedArrayOfRisks = finalRisks;
              previousCloudAccountRisks = updatedPreviousRisks;
            }
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            // * Flatten the arrays inside the previousCloudAccountRisks object
            // Check if the size (number of keys) of previousCloudAccountRisks is greater than
            // the number of selected cloud account IDs.
            if (_.size(previousCloudAccountRisks) > selectedCloudAccountIds.length) {
              // If there are more risks in the previousCloudAccountRisks than selected accounts,
              // we filter the risks to include only those that correspond to the selected accounts.
              flattenedArrayOfRisks = _.flatMap(
                selectedCloudAccountIds,
                eachAccount => previousCloudAccountRisks[eachAccount] || []
              );
            } else {
              // If the number of risks in previousCloudAccountRisks is less than or equal to
              // the number of selected accounts, we consider all the risks. We flatten the array
              // of risks for each account into a single array of risks.
              flattenedArrayOfRisks = _.flatten(_.values(previousCloudAccountRisks));
            }

            yield put({
              type: 'save',
              payload: {
                newData: previousCloudAccountRisks,
                filteredRisks: flattenedArrayOfRisks, //filtered data if there's any filter applied from sec dashboard
                allRisks: flattenedArrayOfRisks,
              },
            });
            yield put({
              type: 'applyFilters',
            });
          } catch (err) {
            throw err;
          }
        } else {
          yield put({
            type: 'save',
            payload: {
              loading: false,
              allRisks: [],
              filteredRisks: [],
            },
          });
        }
      } catch (error) {
        console.log('error from get risks :>> ', error);
        yield put({
          type: 'save',
          payload: {
            loading: false,
            allRisks: [],
            filteredRisks: [],
          },
        });
      }
    },

    *getStandards({ payload }, { call, put, takeEvery, take }) {
      try {
        const service = yield call(list, {
          module: 'Regulations',
        });
        function* push(response) {
          if (response) {
            let standards = response.map(eachResponse => eachResponse.reportId);
            let standardsWithSignatures = [];
            response.forEach(standard => {
              let singleStandardWithSignatures = { title: standard.reportId, signatures: [] };
              standard.config.customRules.forEach(customRule => {
                customRule.signatures.forEach(signature => {
                  singleStandardWithSignatures.signatures.push(signature['signatureName']);
                });
              });
              standardsWithSignatures.push({
                ...singleStandardWithSignatures,
                signatures: Array.from(new Set(singleStandardWithSignatures.signatures)),
              });
            });
            yield put({
              type: 'save',
              payload: {
                standards,
                standardsWithSignatures,
              },
            });
          }
        }
        yield takeEvery(service, push);
        // unsubscribe & clear when this action fired
        yield take(`CANCEL_WATCH/risk_${payload.cloudAccount}`);
        service.close();
      } catch (error) {
        console.log(error);
        yield put({
          type: 'save',
          payload: {
            standards: [],
          },
        });
      }
    },

    *getCategoryOfRisk({ payload }, { call, put, takeEvery, take }) {
      try {
        const service = yield call(list, {
          module: 'Global',
        });
        function* push(response) {
          try {
            if (response.length > 1) {
              let categories = response.find(each => each.id === 'signatureCategories').categories;
              yield put({
                type: 'save',
                payload: {
                  categories,
                },
              });
            }
          } catch (err) {
            console.log('error from get standards', err);
          }
        }
        yield takeEvery(service, push);
        // unsubscribe & clear when this action fired
        yield take(`CANCEL_WATCH/risk_${payload.cloudAccount}`);
        service.close();
      } catch (error) {
        console.log(error);
        yield put({
          type: 'save',
          payload: {
            categories: {},
          },
        });
      }
    },

    *applyFilters({}, { call, put, select }) {
      try {
        const path = yield select(state => state.organisation.spRisks); // Get suppresion path
        const selectedCloudAccounts = yield select(
          state => state.cloudAccount.selectedCloudAccounts
        );
        const {
          cloudProvider,
          severity,
          standards,
          service,
          categories,
          dataSource,
          timeDiscovered,
          securityGroups,
        } = yield select(state => state.Risks.filtersApplied);
        const {
          searchText,
          allRisks,
          filteredRisks,
          dataSourceCollection,
          currentPage,
          sortBy,
          sortOrder,
          tabName,
          showSuppress,
          selectedCartographyCloudAccount,
        } = yield select(state => state.Risks);

        const { globalCategories } = yield select(state => state.global);
        const { metadata } = yield select(state => state.signatureMetaData);
        const { list } = yield select(state => state.customSignatures);

        let filteredRisksCopy;
        let selectedCloudAccIds = selectedCloudAccounts.map(eachAcc => {
          return eachAcc.id;
        });

        switch (tabName.replace(/\s/g, '')) {
          case 'CartogrpahySignatures':
            if (Object.keys(selectedCartographyCloudAccount)?.length) {
              const id = selectedCartographyCloudAccount.awsAccountId;
              const cloudAccountDocId = selectedCartographyCloudAccount.id;
              const orgId = selectedCartographyCloudAccount.orgId;
              const cloudType = selectedCartographyCloudAccount.provider;

              const cloudAccounts = [
                {
                  cloudType,
                  id,
                },
              ];

              try {
                // Fetch cartography signatures
                const response = yield call(
                  fetchCartographySignatures,
                  cloudAccounts,
                  cloudAccountDocId,
                  orgId
                );

                if (response && response.updatedSignatures) {
                  filteredRisksCopy = response.updatedSignatures;
                } else {
                  console.warn("No 'updatedSignatures' found in response.");
                  filteredRisksCopy = [];
                }
              } catch (error) {
                console.error('Error fetching Cartography Signatures:', error);
              }
            } else {
              const errorMessage =
                'This feature only works with AWS as the provider. Please select an AWS account to proceed.';

              console.error(errorMessage);
            }

            break;
          case 'CustomSignatures':
            const newList = addSuppressedFlagsForRisks(list, path, '', selectedCloudAccIds);
            filteredRisksCopy = [...newList];
            break;
          case 'SignaturesLibrary':
            //add suppressed flags in metadata
            const metaData = addCategoryToRisk(metadata, globalCategories);
            const newMetaData = addSuppressedFlagsForRisks(metaData, path, '', selectedCloudAccIds);
            filteredRisksCopy = [...newMetaData];
            break;
          default:
            const newAllRisks = addSuppressedFlagsForRisks(allRisks, path, '', selectedCloudAccIds);

            filteredRisksCopy = mergedRisks(newAllRisks);
            break;
        }

        if (categories?.length) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
            categories.includes(eachRisk.category.replace(/\s/g, '').toLowerCase())
          );
        }
        if (cloudProvider?.length) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
            cloudProvider.includes(eachRisk.cloud)
          );
        }
        if (severity?.length) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
            severity.includes(eachRisk?.risk?.toLowerCase())
          );
        }
        if (service?.length) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
            service.includes(eachRisk.service)
          );
        }
        if (securityGroups?.length) {
          filteredRisksCopy = filteredRisksCopy?.filter(each =>
            securityGroups.includes(each?.group?.toLowerCase().trim())
          );
        }
        // if (standards.length) {
        //   console.log('^*SERVICE WALA IF*^');
        //   filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
        //     standards.includes(eachRisk.standards)
        //   );
        // }
        if (searchText) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk => {
            return (
              (eachRisk?.messageFail &&
                eachRisk?.messageFail?.toLowerCase().search(searchText.toLowerCase()) !== -1) ||
              (eachRisk?.cveID &&
                eachRisk?.cveID?.toLowerCase().search(searchText.toLowerCase()) !== -1) ||
              (eachRisk?.name &&
                eachRisk?.name?.toLowerCase().search(searchText.toLowerCase()) !== -1)
            );
          });
        }
        if (dataSource?.length) {
          if (dataSource.includes('Signatures')) {
            filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
              dataSourceCollection[0].categories.includes(eachRisk.category)
            );
          }
          if (dataSource.includes('Cloudxray')) {
            filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
              dataSourceCollection[1].categories.includes(eachRisk.category)
            );
          }
          if (dataSource.includes('Tenable')) {
            filteredRisksCopy = filteredRisksCopy?.filter(eachRisk =>
              dataSourceCollection[2].categories.includes(eachRisk.category)
            );
          }
        }
        if (sortBy) {
          switch (sortBy) {
            case 'First Seen':
              filteredRisksCopy = filteredRisksCopy.sort((firstRisk, secondRisk) =>
                getSortConditionBySortOrder(
                  sortOrder,
                  secondRisk?.firstSeen
                    ? new Date(secondRisk?.firstSeen).getTime()
                    : sortOrder === 'dsc'
                    ? Infinity
                    : 0,
                  firstRisk?.firstSeen
                    ? new Date(firstRisk?.firstSeen).getTime()
                    : sortOrder === 'dsc'
                    ? Infinity
                    : 0
                )
              );
              break;
            case 'Last Seen':
              filteredRisksCopy = filteredRisksCopy?.sort((firstRisk, secondRisk) =>
                getSortConditionBySortOrder(
                  sortOrder,
                  secondRisk?.lastSeen
                    ? new Date(secondRisk?.lastSeen).getTime()
                    : sortOrder === 'dsc'
                    ? Infinity
                    : 0,
                  firstRisk?.lastSeen
                    ? new Date(firstRisk?.lastSeen).getTime()
                    : sortOrder === 'dsc'
                    ? Infinity
                    : 0
                )
              );
              break;
            case 'Affected Resources':
              // Following function will cater to the sorting of affected resources asc and dcs both based on the provided sort order
              filteredRisksCopy = sortRisks(sortOrder, filteredRisksCopy);
              break;
            case 'Severity':
              filteredRisksCopy = assignRiskLevels(filteredRisksCopy);
              filteredRisksCopy = filteredRisksCopy?.sort((firstRisk, secondRisk) =>
                getSortConditionBySortOrder(
                  sortOrder,
                  secondRisk?.riskLevel
                    ? secondRisk?.riskLevel
                    : sortOrder === 'asc'
                    ? Infinity
                    : 0,
                  firstRisk?.riskLevel ? firstRisk?.riskLevel : sortOrder === 'asc' ? Infinity : 0
                )
              );
              break;
            default:
              filteredRisksCopy = filteredRisksCopy;
              break;
          }
        }

        let totalSuppressedRisks = filteredRisksCopy?.filter(v => v.suppress === true).length;
        filteredRisksCopy = filteredRisksCopy?.filter(each => each.affected !== 0);

        if (!showSuppress) {
          filteredRisksCopy = filteredRisksCopy?.filter(eachRisk => eachRisk.suppress === false);
        }

        yield put({
          type: 'save',
          payload: {
            counts: {
              totalSuppressedRisks: totalSuppressedRisks,
            },
            filteredRisks: filteredRisksCopy,
          },
        });
        yield put({
          type: 'pageChangeNew',
          payload: {
            filteredRisks: filteredRisksCopy,
          },
        });
      } catch (error) {
        console.log('Error from apply filters: ', error.message);
      }
    },

    *saveFilterToState({ payload }, { put, select }) {
      // first dave the payload in the global state of risks
      yield put({
        type: 'save',
        payload: {
          ...payload,
        },
      });
      // check if pushParamsToUrl is true
      if (payload.pushParamsToUrl) {
        let riskFiltersApplied = yield select(state => state.Risks.filtersApplied);
        const { tabName, searchText, sortBy, showSuppress, sortOrder } = yield select(
          state => state.Risks
        );

        // push filters to url
        let urlFilters = [
          { type: 'tabName', value: tabName.replace(/\s/g, '') },
          { type: 'searchValue', value: searchText },
          { type: 'sortOrder', value: sortOrder },
          { type: 'sortBy', value: sortBy },
          { type: 'showSuppress', value: showSuppress },
          { type: 'cloudProvider', value: riskFiltersApplied['cloudProvider'] },
          { type: 'severity', value: riskFiltersApplied['severity'] },
          { type: 'securityGroups', value: riskFiltersApplied['securityGroups'] },
          { type: 'service', value: riskFiltersApplied['service'] },
          { type: 'categories', value: riskFiltersApplied['categories'] },
          { type: 'dataSource', value: riskFiltersApplied['dataSource'] },
          // { type: 'TimeDiscoveredTab', value: riskFiltersApplied['TimeDiscoveredTab'] },
        ];
        // if pushParamsToUrl is true then push the url array into url
        pushParamsToUrl(urlFilters, '', 'risksFilters');
      }
      // and call applyFilters to apply the filters on the data
      yield put({
        type: 'applyFilters',
        payload: {
          isInitialFetch: true,
        },
      });
    },

    *saveFilterPreset({ payload }, { put, select, call }) {
      try {
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: true,
          },
        });
        let { uid, orgId, presetName } = payload;
        let riskFiltersApplied = yield select(state => state.Risks.filtersApplied);

        const response = yield call(savePreset, {
          uid,
          orgId,
          filterPreset: { name: presetName, riskFiltersApplied, createdAt: new Date() },
        });
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
        if (!response) {
          notification.error({
            message: 'Something went wrong',
          });
        }
      } catch (error) {
        console.error('Error from Save Filter Preset: ', error);
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
      }
    },

    *deleteFilterPreset({ payload }, { put, call }) {
      try {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: true,
          },
        });
        const { name, uid, preset } = payload;

        const response = yield call(deletePreset, { name, uid, preset });
      } catch (error) {
        console.log('Error from Delete Filter Preset: ', error);
        notification.error({
          message: 'Error while deleting preset',
        });
      } finally {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: false,
          },
        });
      }
    },
    *pageChangeNew({ payload }, { put, select }) {
      try {
        const {
          currentPage = 1,
          isInitialFetch = false,
          filteredRisks,
          pageSize: newPageSize,
        } = payload;

        let pageSize = yield select(state => state.Risks.pageSize);
        let paginatedResources = yield select(state => state.Risks.filteredRisks);

        // change the page size from 12 to 24,36 or more
        if (newPageSize) {
          pageSize = newPageSize;
        }

        // if (isInitialFetch) {
        //   paginatedResources = [...allRisks];
        // }

        if (filteredRisks) {
          paginatedResources = filteredRisks;
        }
        let totalPages = Math.ceil(paginatedResources?.length / pageSize);
        paginatedResources = paginatedResources?.slice(
          (currentPage - 1) * pageSize,
          (currentPage - 1) * pageSize + pageSize
        );
        yield put({
          type: 'save',
          payload: {
            currentPage,
            paginatedResources,
            loading: false,
            filterResourcesLoading: false,
            totalPages,
            pageSize,
          },
        });
      } catch (error) {
        console.log('Error from pageChangeNew :>> ', error);
      }
    },

    *saveState({ payload }, { put }) {
      yield put({
        type: 'save',
        payload,
      });
    },
  },
  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    saveReport(state, { payload }) {
      return {
        ...state,
        report: payload,
        risksLoader: false,
      };
    },
    reset(state) {
      return {
        ...state,
        allRisks: [],
        filteredRisks: [],
        loading: false,
        paginatedResources: [],
      };
    },
  },
};

// **************** EXTRA FUNCTIONS **********************
const assignRiskLevels = data => {
  let globalRiskLevels = GLOBAL_META_CONFIG.SEVERITY_LEVELS_WITH_MAGNITUDES;
  data = data.map(riskItem => {
    let riskLevel;
    switch (riskItem.risk.toLowerCase()) {
      case globalRiskLevels.CRITICAL.value:
        riskLevel = globalRiskLevels.CRITICAL.level;
        break;
      case globalRiskLevels.HIGH.value:
        riskLevel = globalRiskLevels.HIGH.level;
        break;
      case globalRiskLevels.MEDIUM.value:
        riskLevel = globalRiskLevels.MEDIUM.level;
        break;
      case globalRiskLevels.LOW.value:
        riskLevel = globalRiskLevels.LOW.level;
        break;
      default:
        riskLevel = globalRiskLevels.LOW.level;
        break;
    }
    return { ...riskItem, riskLevel };
  });
  return data;
};

// ? This function adds the count of total affected resources if the resources are duplicate
const mergedRisks = risks => {
  let tempRisks = [];
  risks.map(eachRisk => {
    let index = tempRisks.findIndex(each => each.name === eachRisk.name);
    if (index > -1) {
      tempRisks[index].affected = Number(tempRisks[index].affected) + Number(eachRisk.affected);
      tempRisks[index].total = Number(tempRisks[index].total) + Number(eachRisk.total);
    } else {
      tempRisks.push(eachRisk);
    }
  });
  return tempRisks;
};

const getPriority = severity => {
  return {
    critical: 4,
    high: 3,
    medium: 2,
    low: 1,
  }[severity && severity.toLowerCase()];
};

// TODO: This function need to be exactly same as the one use in the inventory modal in the frontend
const sortRisks = (sortOrder, risks) => {
  if (!risks || (Array.isArray(risks) && !risks?.length)) return;
  return risks.sort((a, b) => {
    if (a.affected !== b.affected) {
      if (sortOrder === 'asc') return a.affected - b.affected; // Sort affected in ascending order (means less affected first)
      return b.affected - a.affected; // Sort affected in descending order (means more affected first)
    } else {
      if (getPriority(b.risk) !== getPriority(a.risk)) {
        return getPriority(b.risk) - getPriority(a.risk); // Sort based on risk level (high > medium > low) (only if affected is same)
      } else {
        return b.cvssScore - a.cvssScore; // Sort based on cvssScore in descending order (higher cvssScore comes first if severity is equal)
      }
    }
  });
};
// ****************************************************************************

// **************** HELPERS FUNCTIONS ********************

// **** Use lodash functions to transform the data and generates signatures of each resource
const signaturesCustomizer = (
  objValue,
  srcValue,
  key,
  signaturesMetaData,
  customSignaturesMetaData,
  globalCategories
) => {
  if (!objValue) {
    let signatruesMetaInfo;
    // * If signatureId is not present in result, set default values with signatures meta data too
    // ? Optimized way to assign category to each signature from the globalCategoryes obj and if it not there then we are taking vulnerabilities
    if (signaturesMetaData[key]) {
      signatruesMetaInfo = signaturesMetaData[key];
    } else {
      signatruesMetaInfo = customSignaturesMetaData?.find(cs => cs.id === key);
    }
    return {
      ...signatruesMetaInfo,
      name: key,
      category: _.findKey(globalCategories, value => value.includes(key)) || 'vulnerabilities',
      total: 1,
      affected: srcValue.status === 'fail' ? 1 : 0,
      firstSeen: new Date(srcValue.firstSeen._seconds * 1000),
      lastSeen: new Date(srcValue.lastSeen._seconds * 1000),
      suppress: false,
    };
  } else {
    // * If signatureId is present, update values based on conditions
    return {
      ...objValue,
      total: objValue.total + 1,
      affected: objValue.affected + (srcValue.status === 'fail' ? 1 : 0),
      firstSeen: new Date(
        Math.min(objValue.firstSeen.getTime(), srcValue.firstSeen._seconds * 1000)
      ),
      lastSeen: new Date(Math.max(objValue.lastSeen.getTime(), srcValue.lastSeen._seconds * 1000)),
    };
  }
};

const cvesCustomizer = (objValue, srcValue, key, resourceData) => {
  // Convert Firestore Timestamps to JavaScript Date objects
  const firstSeen = new Date(
    srcValue.firstSeen._seconds * 1000 + srcValue.firstSeen._nanoseconds / 1e6
  );
  const lastSeen = new Date(
    srcValue.lastSeen._seconds * 1000 + srcValue.lastSeen._nanoseconds / 1e6
  );
  if (!objValue) {
    // If CVE ID is not present in objValue, set default values
    // Customize this logic based on your requirements for CVEs

    return {
      ...srcValue,
      name: key,
      total: 1,
      affected: 1, // Assuming each cve affected one resource
      firstSeen,
      lastSeen,
      cloudAccountId: resourceData.cloudAccountId,
      category: 'osVulnerabilities',
      messageFail: srcValue.title,
      name: srcValue.cveID,
      description: srcValue.description || srcValue.title,
      risk:
        srcValue?.cvss3Severity?.toLowerCase() === 'n/a'
          ? 'low'
          : srcValue?.cvss3Severity?.toLowerCase(),
      cvss3Severity:
        srcValue.cvss3Severity?.toLowerCase() === 'n/a' ? 'low' : srcValue.cvss3Severity,
      cvssScore: srcValue.cvss3Severity.toLowerCase() === 'n/a' ? 0 : srcValue.cvssScore,
      service: resourceData.service,
      cloud: resourceData.provider,
      suppress: false,
      // ... other properties for CVEs
    };
  } else {
    // If CVE ID is present, update values based on conditions
    // Customize this logic based on your requirements for updating CVEs

    return {
      ...objValue,
      total: objValue.total + 1,
      affected: objValue.affected + 1, // Assuming each cve affects one resource
      firstSeen: new Date(Math.min(objValue.firstSeen.getTime(), firstSeen * 1000)),
      lastSeen: new Date(Math.max(objValue.lastSeen.getTime(), lastSeen * 1000)),
      // ... other properties for CVEs
    };
  }
};

const malwaresCustomizer = (objValue, srcValue, key, resourceData) => {
  // Convert Firestore Timestamps to JavaScript Date objects
  const firstSeen = new Date(
    srcValue.firstSeen._seconds * 1000 + srcValue.firstSeen._nanoseconds / 1e6
  );
  const lastSeen = new Date(
    srcValue.lastSeen._seconds * 1000 + srcValue.lastSeen._nanoseconds / 1e6
  );
  if (!objValue) {
    // If malware ID is not present in objValue, set default values
    // Customize this logic based on your requirements for malwares

    return {
      ...srcValue,
      name: key,
      cloudAccountId: resourceData.cloudAccountId,
      name: srcValue.malwareId,
      messageFail: srcValue.title,
      risk: srcValue.severity,
      category: 'malware',
      service: resourceData.service,
      cloud: resourceData.provider,
      total: 1,
      affected: 1, // Assuming each malware affects one resource
      firstSeen,
      lastSeen,
      // ... other properties for malwares
    };
  } else {
    // If malware ID is present, update values based on conditions
    // Customize this logic based on your requirements for updating malwares
    return {
      ...objValue,
      total: objValue.total + 1,
      affected: objValue.affected + 1, // Assuming each malware affects one resource
      firstSeen: new Date(Math.min(objValue.firstSeen.getTime(), firstSeen * 1000)),
      lastSeen: new Date(Math.max(objValue.lastSeen.getTime(), lastSeen * 1000)),
      // ... other properties for malwares
    };
  }
};

const thirdPartyCustomizer = (objValue, srcValue, key, resourceData) => {
  if (!objValue) {
    // * If signatureId is not present in result, set default values with signatures meta data too
    // ? Optimized way to assign category to each signature from the globalCategoryes obj and if it not there then we are taking vulnerabilities
    return {
      name: key,
      category: CNS_CATEGORIES.THIRD_PARTY_RISK,
      cloudAccountId: resourceData.cloudAccountId,
      service: resourceData.service,
      cloud: resourceData.provider,
      messageFail: srcValue.statusMessage,
      description: srcValue.pageDetail,
      dataSource: srcValue.dataSource,
      risk: srcValue.risk,
      suppress: false,
      firstSeen: srcValue.firstSeen,
      lastSeen: srcValue.lastSeen,
      affected: 1,
      total: 1,
    };
  } else {
    // * If signatureId is present, update values based on conditions
    return {
      ...objValue,
      total: objValue.total + 1,
      affected: objValue.affected + (srcValue.status === 'fail' ? 1 : 0),
    };
  }
};

// ? MAIN FUNCTION THAT HAS ALL THE RESPONSIBILTY TO MERGE EVERY KIND OF RISKS
const mergeSignatureData = (
  allResources,
  signaturesMetaData,
  customSignaturesMetaData,
  globalCategories
) => {
  let mainSignatures = {};

  _.forEach(allResources, resource => {
    // * Creating resourceData which might need in cves and malwares risks
    const resourceData = {
      service: resource.service,
      provider: resource.provider,
    };
    // * 1. Check if resource has signatures and it is not empty
    if (resource.signatures && !_.isEmpty(resource.signatures)) {
      _.mergeWith(mainSignatures, resource.signatures, (objValue, srcValue, key) =>
        signaturesCustomizer(
          objValue,
          srcValue,
          key,
          signaturesMetaData,
          customSignaturesMetaData,
          globalCategories
        )
      );
    }

    // * 2. Check if resource has cloudXRayRisks.CVEs and it is not empty
    if (
      resource.cloudXRayRisks &&
      resource.cloudXRayRisks.CVEs &&
      !_.isEmpty(resource.cloudXRayRisks.CVEs)
    ) {
      const cvesData = resource.cloudXRayRisks.CVEs;

      // Process CVEs data and merge with mainSignatures
      _.mergeWith(mainSignatures, cvesData, (objValue, srcValue, key) =>
        cvesCustomizer(objValue, srcValue, key, resourceData)
      );
    }

    // * 3. Check if resource has cloudXRayRisks.malwares and it is not empty
    if (
      resource.cloudXRayRisks &&
      resource.cloudXRayRisks.malwares &&
      !_.isEmpty(resource.cloudXRayRisks.malwares)
    ) {
      const malwaresData = resource.cloudXRayRisks.malwares;
      // Process malwares data and merge with mainSignatures
      _.mergeWith(mainSignatures, malwaresData, (objValue, srcValue, key) =>
        malwaresCustomizer(objValue, srcValue, key, resourceData)
      );
    }

    // * 4. Check if resource has thirdPartyRisks and it is not empty
    if (resource.thirdPartyRisks && !_.isEmpty(resource.thirdPartyRisks)) {
      const thirdPartyRisksData = resource.thirdPartyRisks;

      // Process malwares data and merge with mainSignatures
      _.mergeWith(mainSignatures, thirdPartyRisksData, (objValue, srcValue, key) =>
        thirdPartyCustomizer(objValue, srcValue, key, resourceData)
      );
    }
  });

  return mainSignatures;
};

const computeRisksForAccount = (
  accountId,
  cloudAccountsReports,
  signaturesMetaData,
  customSignaturesMetaData,
  globalCategories
) => {
  const allResourcesOfCurrentAccount = cloudAccountsReports[accountId];
  const mergedRisksData = mergeSignatureData(
    allResourcesOfCurrentAccount,
    signaturesMetaData,
    customSignaturesMetaData,
    globalCategories
  );
  return _.values(mergedRisksData);
};

const processCloudAccounts = (
  selectedCloudAccountIds,
  previousCloudAccountRisks,
  cloudAccountsReports,
  signaturesMetaData,
  customSignaturesMetaData,
  globalCategories
) => {
  let flattenedArrayOfRisks = [];

  selectedCloudAccountIds.forEach(eachCA => {
    // * CURRENT ACCOUNT IS PRESENT IN PREVOUS RISKS
    if (previousCloudAccountRisks && Object.keys(previousCloudAccountRisks).includes(eachCA)) {
      flattenedArrayOfRisks = [...flattenedArrayOfRisks, ...previousCloudAccountRisks[eachCA]];
    } else {
      // * CURRENT ACCOUNT NOT PRESENT IN PREVOUS RISKS SO COMPUTING NEW RISKS
      const currentCloudAccountRisks = computeRisksForAccount(
        eachCA,
        cloudAccountsReports,
        signaturesMetaData,
        customSignaturesMetaData,
        globalCategories
      );
      previousCloudAccountRisks = {
        ...previousCloudAccountRisks,
        [eachCA]: currentCloudAccountRisks,
      };
      flattenedArrayOfRisks = [...flattenedArrayOfRisks, ...currentCloudAccountRisks];
    }
  });

  return { previousCloudAccountRisks, flattenedArrayOfRisks };
};

// ****************************************************************************
