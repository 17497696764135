import { cloudViewCall, getCloudViewQuery } from '@/services/cloudView';
import { SCAN_STATUS } from '@/utils/constant';
import { notification } from 'antd';
import { decryptAES } from '@/utils/utils';
import { deleteCloudViewPreset, saveCloudViewPreset } from '@/services/cloudViewPreset';

const tempResp = {
  nodes: [
    {
      id: '5000',
      type: 'turbo',
      data: {
        label: 'Yunus Test User (IAM)',
        elemProps: {
          userName: 'Ali',
          status: 'MFA Not Enabled',
          arn: 'arn:aws:iam::123456789012:user/Ali',
        },
        count: { risk: 'MFA Disabled' },
      },
      position: {
        x: 100,
        y: 100,
      },
    },
    {
      id: '5001',
      type: 'turbo',
      data: {
        label: 'Ahmed (IAM)',
        count: { risk: 'MFA Disabled' },
        elemProps: {
          userName: 'Ahmed',
          status: 'MFA Not Enabled',
          arn: 'arn:aws:iam::123456789012:user/Ahmed',
        },
      },
      position: {
        x: 300,
        y: 100,
      },
    },
    {
      id: '6000',
      type: 'turbo',
      data: {
        label: '330215083007 (CloudAccount)',
        elemProps: {
          accountId: '123456789012',
          accountName: 'ProdAccount',
          cloudProvider: 'AWS',
        },
      },
      position: {
        x: 200,
        y: 200,
      },
    },
    {
      id: '7000',
      type: 'turbo',
      data: {
        label: 'AdminRole',
        elemProps: {
          roleName: 'AdminRole',
          arn: 'arn:aws:iam::123456789012:role/AdminRole',
        },
      },
      position: {
        x: 100,
        y: 300,
      },
    },
    {
      id: '7001',
      type: 'turbo',
      data: {
        label: 'DeveloperRole',
        elemProps: {
          roleName: 'DeveloperRole',
          arn: 'arn:aws:iam::123456789012:role/DeveloperRole',
        },
      },
      position: {
        x: 300,
        y: 300,
      },
    },
    {
      id: '8000',
      type: 'turbo',
      data: {
        label: 'AdministratorAccess',
        elemProps: {
          policyName: 'AdministratorAccess',
          arn: 'arn:aws:iam::123456789012:policy/AdministratorAccess',
          effect: 'Allow',
        },
      },
      position: {
        x: 100,
        y: 400,
      },
    },
    {
      id: '8001',
      type: 'turbo',
      data: {
        label: 'AmazonEC2ReadOnlyAccess',
        elemProps: {
          policyName: 'AmazonEC2ReadOnlyAccess',
          arn: 'arn:aws:iam::123456789012:policy/AmazonEC2ReadOnlyAccess',
          effect: 'Allow',
        },
      },
      position: {
        x: 300,
        y: 400,
      },
    },
    {
      id: '2000',
      type: 'turbo',
      data: {
        label: 'i-0d9274gdded3cddc0 (EC2Instance)',
        elemProps: {
          instanceId: 'i-123ec2example',
          instanceName: 'Production-Instance',
          arn: 'arn:aws:ec2:us-east-1:123456789012:instance/i-123ec2example',
          region: 'us-east-1',
        },
      },
      position: {
        x: 100,
        y: 500,
      },
    },
    {
      id: '2001',
      type: 'turbo',
      data: {
        label: '2024-10-backup (S3Bucket)',
        elemProps: {
          bucketName: 'prod-bucket',
          arn: 'arn:aws:s3:::prod-bucket',
          region: 'us-east-1',
        },
      },
      position: {
        x: 300,
        y: 500,
      },
    },
  ],
  edges: [
    {
      source: '5000',
      target: '6000',
      type: 'BELONGS_TO',
      data: {},
    },
    {
      source: '5001',
      target: '6000',
      type: 'BELONGS_TO',
      data: {},
    },
    {
      source: '6000',
      target: '7000',
      type: 'CONTAINS_ROLE',
      data: {},
    },
    {
      source: '6000',
      target: '7001',
      type: 'CONTAINS_ROLE',
      data: {},
    },
    {
      source: '7000',
      target: '8000',
      type: 'ATTACHED_POLICY',
      data: {},
    },
    {
      source: '7001',
      target: '8001',
      type: 'ATTACHED_POLICY',
      data: {},
    },
    {
      source: '8000',
      target: '2000',
      type: 'ALLOWS_ACCESS_TO',
      data: {
        service: 'EC2Instance',
        effect: 'Allow',
      },
    },
    {
      source: '8001',
      target: '2001',
      type: 'ALLOWS_ACCESS_TO',
      data: {
        service: 'S3Bucket',
        effect: 'Allow',
      },
    },
  ],
};
export default {
  namespace: 'cloudviz',
  state: {
    loading: false,
    selectedProvider: 'AWS',
    activeButton: '',
    query: '', // query can be typed manually and will be returned from the backend as well
    disable: true, // use this flag for disabling buttons and  throttling
    cloudAccountData: {},
    filteredAccounts: [], // wrt to selected provider
    data: { nodes: [], edges: [], context: '' },
    presetModalLoading: false,
    presetModalState: false,
  },

  effects: {
    *handleCloudProviderSelection({ payload }, { put, select }) {
      try {
        let { providerName } = payload;

        if (!providerName) providerName = 'AWS';

        // saves provider name
        yield put({
          type: 'save',
          payload: {
            selectedProvider: providerName,
          },
        });

        // take all cloud accounts
        const { selectedCloudAccounts } = yield select(state => state.cloudAccount);
        const filteredCloudAccounts = selectedCloudAccounts;

        if (filteredCloudAccounts && filteredCloudAccounts.length) {
          yield put({
            type: 'save',
            payload: {
              filteredAccounts: filteredCloudAccounts,
              cloudAccountData: filteredCloudAccounts.filter(
                e => e.provider && e.provider.toLowerCase() !== 'azure'
              )[0], // skip this logic once cloud view for azure is done
              activeButton: '',
              query: '',
              disable: true,
              data: { nodes: [], edges: [], context: '' },
            },
          });
        }
      } catch (error) {
        console.log('Error from handleCloudProviderSelection: ', error.message);
      }
    },
    *handleCloudAccountSelection({ payload }, { put, select }) {
      try {
        const { account } = payload;

        const { query, activeButton } = yield select(state => state.cloudviz);
        let [queryCopy, activeButtonCopy] = [query, activeButton];

        yield put({
          type: 'save',
          payload: {
            cloudAccountData: account,
          },
        });

        if (
          account?.status &&
          account.status === SCAN_STATUS.COMPLETED.status &&
          account.cloudViewConfig &&
          account.cloudViewConfig.status === SCAN_STATUS.COMPLETED.status
        ) {
          yield put({
            type: 'cloudviz/getQuery',
            payload: {
              type: 'internet-facing-instances',
              disable: false,
            },
          });
        } else {
          yield put({
            type: 'save',
            payload: {
              query: 'Error in cloud account status',
              activeButton: '',
              disable: true,
              data: { nodes: [], edges: [], context: '' },
            },
          });
        }

        yield put({
          type: 'save',
          payload: {
            query: queryCopy,
            activeButton: activeButtonCopy,
          },
        });
      } catch (error) {
        console.log('Error from handleCloudAccountSelection: ', error.message);
      }
    },

    *getQuery({ payload }, { put, select, call }) {
      const { type } = payload;

      const { cloudAccountData } = yield select(state => state.cloudviz);

      if (cloudAccountData && Object.keys(cloudAccountData)?.length) {
        // Use 'length' to ensure it's an array and has items
        yield put({
          type: 'save',
          payload: {
            loading: true,
            activeButton: type,
            disable: payload.disable,
            query: 'loading...',
          },
        });

        const cloudViewSelectedAccount = cloudAccountData; // Directly use from state
        const cloudAccounts = cloudAccountCredentials([cloudViewSelectedAccount]); // Ensure this function is defined or imported
        const URI = getURI(cloudAccounts); // Ensure this function is defined or imported

        const response = yield call(getCloudViewQuery, {
          cloudAccounts,
          URI,
          type,
        });

        if (response?.status === 200) {
          yield put({
            type: 'save',
            payload: {
              query: response.query,
            },
          });
          yield put({
            type: 'runQuery',
            payload: {
              type,
              URI,
            },
          });
        } else {
          notification.error({ message: 'Something went wrong!' });
          yield put({
            type: 'save',
            payload: {
              query: '',
              loading: false,
            },
          });
        }
      } else {
        notification.error({ message: 'No selected cloud accounts available.' });
        yield put({
          type: 'save',
          payload: {
            query: 'No data',
            loading: false,
          },
        });
      }
    },

    *runQuery({ payload }, { select, call, put }) {
      const { query, cloudAccountData } = yield select(state => state.cloudviz);
      if (!cloudAccountData || Object.keys(cloudAccountData).length === 0) {
        notification.error({ message: 'No selected cloud accounts available.' });
        yield put({
          type: 'save',
          payload: {
            query: 'No data',
            loading: false,
          },
        });
        return;
      }
      const { rawQuery, type } = payload;
      yield put({
        type: 'save',
        payload: {
          loading: true,
          activeButton: type,
        },
      });
      const cloudAccounts = cloudAccountCredentials([cloudAccountData]);

      let URI = 'run-query'; // by default URI
      if (!rawQuery) {
        URI = `${getURI(cloudAccounts)}/data`;
      }

      const response = yield call(cloudViewCall, {
        rawQuery,
        query,
        cloudAccounts,
        URI,
        type,
        orgId: cloudAccountData.orgId,
        cloudAccountDocId: cloudAccountData.id,
      });

      if (response?.status && response.status === 200) {
        const finalResp = isOverPrivilegedUserQuery(query) ? tempResp : response;
        yield put({
          type: 'save',
          payload: {
            // data: response,
            data: finalResp,
          },
        });
      } else {
        notification.error({ message: `Something went wrong!` });
      }
      yield put({
        type: 'save',
        payload: {
          loading: false,
        },
      });
    },
    *saveCloudViewPreset({ payload }, { put, select, call }) {
      try {
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: true,
          },
        });
        let { uid, orgId, presetName } = payload;
        let query = yield select(state => state.cloudviz.query);

        const response = yield call(saveCloudViewPreset, {
          uid,
          orgId,
          cloudViewPreset: { name: presetName, query, createdAt: new Date() },
        });
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
        if (!response) {
          notification.error({
            message: 'Something went wrong',
          });
        }
      } catch (error) {
        console.error('Error from Save Filter Preset: ', error);
        yield put({
          type: 'save',
          payload: {
            presetModalLoading: false,
            presetModalState: false,
          },
        });
      }
    },
    *deleteFilterPreset({ payload }, { put, call }) {
      try {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: true,
          },
        });
        const { name, uid, preset } = payload;

        const response = yield call(deleteCloudViewPreset, { name, uid, preset });
      } catch (error) {
        console.log('Error from Delete Filter Preset: ', error);
        notification.error({
          message: 'Error while deleting preset',
        });
      } finally {
        yield put({
          type: 'update',
          payload: {
            presetModalLoading: false,
          },
        });
      }
    },
  },

  reducers: {
    save(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};

const cloudAccountCredentials = selectedCloudAccounts => {
  let credentials = [];
  selectedCloudAccounts.map(each => {
    if (each.provider.toLowerCase() === 'aws') {
      credentials.push({ cloudType: each.provider.toLowerCase(), id: each.awsAccountId });
    } else if (each.provider.toLowerCase() === 'azure' && each.userkeys) {
      const userData = JSON.parse(decryptAES(each.userkeys));
      credentials.push({ cloudType: each.provider.toLowerCase(), id: userData.clientId });
    } else if (each.provider.toLowerCase() === 'gcp' && each.userkeys) {
      const userData = JSON.parse(decryptAES(each.userkeys));
      credentials.push({ cloudType: each.provider.toLowerCase(), id: userData.projectId });
    }
  });
  return credentials;
};

const getURI = cloudAccountsArray => {
  if (cloudAccountsArray.length === 1) return 'posture';
  else if (cloudAccountsArray.length > 1) return 'posture-multiple';
  else return 'run-query';
};

// ! this is a temporary function, we'll remove this later
const isOverPrivilegedUserQuery = query => {
  // console.log('Original Query (PARAMS WALI):', query);

  const overPrivilegedUserQuery = `MATCH (account:AWSAccount {id: "xxxxxxxxx"})-[:RESOURCE]->(user:AWSUser)MATCH (user)-[r1:POLICY]->(policy:AWSPolicy)-[r2:STATEMENT]->(statement:AWSPolicyStatement)WHERE statement.effect = 'Allow'WITH account, user, policy, statement, collect(statement.action) AS allowedActions, r1, r2MATCH (user)-[r3:MEMBER_AWS_GROUP]->(group:AWSGroup)-[r4:POLICY]->(groupPolicy:AWSPolicy)-[r5:STATEMENT]->(groupStatement:AWSPolicyStatement)WHERE groupStatement.effect = 'Allow'WITH account, user, policy, statement, allowedActions, group, groupPolicy, groupStatement, collect(groupStatement.action) AS groupActions, r1, r2, r3, r4, r5WITH account, user, policy, statement, group, groupPolicy, groupStatement, apoc.coll.flatten(allowedActions + groupActions) AS combinedActions, r1, r2, r3, r4, r5UNWIND combinedActions AS actionWITH account, user, policy, statement, group, groupPolicy, groupStatement, collect(action) AS allActions, r1, r2, r3, r4, r5RETURN account, user, policy, statement, group, groupPolicy, groupStatement, allActions, size(allActions) AS NumberOfActions, r1, r2, r3, r4, r5ORDER BY NumberOfActions DESC`;

  // Helper function to remove all whitespace, making comparison easier
  const normalizeString = str =>
    str
      .replace(/\s+/g, '')
      .trim()
      .toLowerCase();

  const formattedQuery = normalizeString(query);
  const formattedOverPrivilegedUserQuery = normalizeString(overPrivilegedUserQuery);

  // console.log('Formatted PARAMS Query:', formattedQuery);
  // console.log('Formatted Defined Query:', formattedOverPrivilegedUserQuery);

  // Log character-by-character comparison if mismatch occurs
  if (formattedQuery !== formattedOverPrivilegedUserQuery) {
    // console.log('Character-by-character mismatch detected.');
    for (
      let i = 0;
      i < Math.max(formattedQuery.length, formattedOverPrivilegedUserQuery.length);
      i++
    ) {
      if (formattedQuery[i] !== formattedOverPrivilegedUserQuery[i]) {
        // console.log(
        //   `Mismatch at character ${i}: PARAMS WALI "${formattedQuery[i]}", DEFINED WALI "${formattedOverPrivilegedUserQuery[i]}"`
        // );
        break;
      }
    }
  }

  return formattedQuery === formattedOverPrivilegedUserQuery;
};
