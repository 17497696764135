const axios = require('axios');

export const fetchCartographySignatures = async () => {
  const url = 'http://3.0.188.198:8282/signatures/fetch';

  const data = {
    cloudAccounts: [
      {
        cloudType: 'aws',
        id: '399692666609',
      },
    ],
    cloudAccountDocId: '9brlpid1730384551082',
    orgId: 'ap-daniyal_engte',
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('new data................', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error; // or handle error as needed
  }
};
