// firebaseConfig.js
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import config from '../../config';

// Initialize Firebase

const isDevenv = process.env.NODE_ENV === 'development';

const firebaseConfig = config?.firebase || {};
// // Auth Emulator setup
// if (APP_ENV === 'localEmulator' && isDevenv) {
//   firebaseConfig.authDomain = 'localhost:9099';
// }

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();
const realtimeDb = firebase.database();
const functions = firebaseApp.functions();

// connect to local firestore and functions emulator if in development
// if (APP_ENV === 'localEmulator' && isDevenv) {
//   firestore.settings({
//     host: 'localhost:8080',
//     ssl: false, // TODO: set to true if using https
//   });
// for functions emulator, see https://firebase.google.com/docs/emulator-suite/connect_functions
//   functions.useFunctionsEmulator('http://localhost:5001');
// }

if (typeof window !== 'undefined' && window.location.hostname === 'localhost' && isDevenv) {
  console.log('Connecting to local emulator');
  // realtimeDb.useEmulator('localhost', 9000);
}

export { firebaseApp, firebase, firestore, firebaseConfig, functions, realtimeDb };
