import { startThread, sendMessage } from '@/services/cloudIQ';
import { message, notification } from 'antd';

const namespace = 'cloudIQ';

export default {
  namespace,

  state: {
    threadId: null,
    messages: [],
    loading: false,
  },

  effects: {
    *startThread(_, { call, put }) {
      yield put({
        type: 'setLoading',
        payload: true,
      });
      try {
        const response = yield call(startThread);
        yield put({
          type: 'saveThreadId',
          payload: response.threadId,
        });
      } catch (error) {
        notification.error({
          message: 'Failed to start thread',
          description: error.message,
        });
      } finally {
        yield put({
          type: 'setLoading',
          payload: false,
        });
      }
    },

    *sendMessage({ payload }, { call, put, select }) {
      const { selectedCloudAccountsIds, orgId } = payload;
      const threadId = yield select(state => state[namespace].threadId);

      // Create a temporary message object to show the user's message immediately
      const tempMessage = {
        id: `temp-${new Date().getTime()}`, // Temporary ID for the message
        object: 'thread.message',
        created_at: Math.floor(Date.now() / 1000), // Unix timestamp
        assistant_id: null,
        thread_id: threadId,
        run_id: null,
        role: 'user', // User's role
        content: [
          {
            type: 'text',
            text: {
              value: payload.message,
              annotations: [],
            },
          },
        ],
        attachments: [],
        metadata: {},
      };

      // Fetch the current chat history from the state
      const currentMessages = yield select(state => state[namespace].messages);

      // Update the state immediately by appending the user's message to the existing chat history
      yield put({
        type: 'updateChatHistory',
        payload: [...currentMessages, tempMessage], // Append the temp message
      });

      yield put({
        type: 'setLoading',
        payload: true,
      });

      try {
        // Send the actual message to the server
        const response = yield call(sendMessage, {
          message: payload.message,
          threadId,
          organizationId: orgId,
          cloudAccountIds: selectedCloudAccountsIds,
        });

        // Update session storage with the updated thread and messages
        sessionStorage.setItem(
          'cloudIQthread',
          JSON.stringify({
            threadId,
            messages: response.messages,
          })
        );

        console.log('response :>> ', response);
        console.log('response.messages[0].created_at :>> ', response.messages[0].created_at);

        const currentUser = yield select(state => state.user.currentUser);
        let cloudIQ_threads = currentUser.cloudIQ_threads || []; // Ensure it's an array

        // Check if the current threadId exists in the user's cloudIQ_threads
        const threadExists = cloudIQ_threads.some(thread => thread === threadId);

        console.log(' lastChatAt ', response.messages[response.messages.length - 1].created_at);

        if (!threadExists) {
          // If the threadId is not present, add it to the array
          cloudIQ_threads = [
            ...cloudIQ_threads,
            {
              threadId,
              startedAt: response.messages[0].created_at,
              lastChatAt: response.messages[response.messages.length - 1].created_at,
            },
          ];

          // Dispatch an action to update the user model with the new thread
          yield put({
            type: 'user/updateUserThreads', // Assuming you have an action in the user model to handle thread updates
            payload: {
              ...currentUser,
              cloudIQ_threads, // Update with the new thread array
            },
          });
        }

        // Merge the existing chat history with the real response
        yield put({
          type: 'updateChatHistory',
          payload: [...currentMessages, ...response.messages.reverse()], // Append new messages from response
        });
      } catch (error) {
        // Handle the error, e.g., display an error notification
        notification.error({
          message: 'Failed to send message',
          description: error.message,
        });

        // Optionally, mark the temp message as 'failed' in the chat history
        yield put({
          type: 'updateChatHistory',
          payload: [
            ...currentMessages,
            {
              ...tempMessage,
              status: 'failed', // Mark the message as failed in case of error
            },
          ],
        });
      } finally {
        yield put({
          type: 'setLoading',
          payload: false,
        });
      }
    },

    *newChat(_, { put, select }) {
      const threadId = yield select(state => state[namespace].threadId);

      if (threadId) {
        sessionStorage.removeItem('cloudIQthread');
      }

      yield put({
        type: 'saveThreadId',
        payload: null,
      });
      yield put({
        type: 'updateChatHistory',
        payload: [],
      });
      yield put({
        type: 'startThread',
      });
    },

    *saveThreadFromLocalStorage({ payload }, { put }) {
      const { threadId, messages } = payload;

      yield put({
        type: 'saveThreadId',
        payload: threadId,
      });
      yield put({
        type: 'updateChatHistory',
        payload: messages.reverse(),
      });
    },
  },

  reducers: {
    setLoading(state, { payload }) {
      return {
        ...state,
        loading: payload,
      };
    },

    saveThreadId(state, { payload }) {
      return {
        ...state,
        threadId: payload,
      };
    },

    updateChatHistory(state, { payload }) {
      return {
        ...state,
        messages: payload,
      };
    },
  },
};
