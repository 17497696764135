export const tooltipContent = {
  cloudAccountName: 'The unique name of your Cloud Account on Cloudnosys platform.' /*  */,
  externalId:
    'This is an added layer of security used to grant Cloudnosys access to your cloud account for risk detection.',
  roleArn: `Cloudnosys role's unique identifier in your AWS account`,
  serviceAccount_clientEmail: `Client Email is your unique identifier in your GCP account`,
  monitoring: 'The frequency of automatic scans on your cloud account.',
  clientId: 'The client Id used for identifying your application.',
  domain: 'The domain associated with your account or application.',
  secret: 'The secret key used for authentication purposes. Ensure it is kept secure.',
  subscriptionId:
    'The subscription ID is a unique identifier for your subscription on the cloud platform.',
  secretExpiryDates: 'The date and time on which the secret key will expire.',
};

export const INSTRUCTION_CONTENT = {
  CHANGE_EXTERNAL_ID: {
    name: 'External ID',
    description:
      'This is an added layer of security used to grant Cloudnosys access to your cloud account for risk detection.',
    action: 'To update the External ID follow these steps:',
    steps: [
      'Login to your connected AWS account.',
      'Go to IAM and then roles .',
      'Locate and click on the role named "cns-role-misconf" .',
      'Click on the tab named "Trust Relationship".',
      'Modify the value of External ID in the code.',
      'Copy the new External ID.',
      'Navigate back to this field and paste the same External ID here.',
    ],
  },
};

export const converageConnections = [
  {
    name: 'Misconfigurations',
    key: 'misconfigurationDetection',
    message: '',
    status: 200,
    loading: false,
    mode: 'basic',
  },
  {
    name: 'Authentication',
    key: 'authentication',
    message: '',
    status: 200,
    loading: false,
    mode: 'basic',
  },
  {
    name: 'Resources Access',
    key: 'resourcesAccess',
    message: '',
    status: 200,
    loading: false,
    mode: 'basic',
  },
  {
    name: 'Eagle Eye',
    key: 'eagleEye',
    message: '',
    status: '',
    loading: false,
    mode: 'advanced',
  },
  {
    name: 'Cloudxray',
    key: 'cloudXray',
    message: '',
    status: '',
    loading: false,
    mode: 'advanced',
  },
];

export const riskTypes = {
  osVulnerabilities: 'OS Vulnerabilities',
  cxrRiskNames: ['OS Vulnerabilities', 'osVulnerabilities', 'Malware'],
  malware: 'Malware',
};

export const SCAN_STATUS = {
  START: {
    status: 'start-scan',
    statusMsg: 'Scan Started',
    scanMessage: 'The resources are being collected - Please wait',
  },
  RUNNING: {
    status: 'running-scan',
    statusMsg: 'Scan Running',
    scanMessage: 'The resources are being collected - Please wait',
  },
  COMPLETED: {
    status: 'completed-scan',
    statusMsg: 'Scan Completed',
    scanMessage: 'Scan finished',
  },
  FAILED: {
    status: 'failed-scan',
    statusMsg: 'Scan Failed',
    scanMessage: 'Ops! Scan failed',
  },
  CANCELLED: {
    status: 'cancelled-scan',
    statusMsg: 'Scan Cancelled',
    scanMessage: 'Scan cancelled',
  },
};

export const SCAN_TYPE = {
  MALWARE: 'MALWARE',
  OS_VULNERABILITIES: 'OS_VULNERABILITIES',
  FULL_SCAN: 'FULL_SCAN',
  BASIC: 'BASIC',
};

export const PROTECTION_LEVEL = {
  BASIC: 'basic',
  ADVANCED: 'advanced',
};

export const CLOUD_PROVIDERS = {
  AWS: 'AWS',
  GCP: 'GCP',
  AZURE: 'AZURE',
};

export const TEST_CONNECTION_TYPES = {
  AUTHENTICATION: 'authentication',
  RESOURCEACCESS: 'resourcesAccess',
  MISCONFIGURATION: 'misconfigurationDetection',
  CLOUDXRAY: 'cloudXray',
  EAGLEEYE: 'eagleEye',
  VPCTEST: 'vpcTest',
};

// export const colorMap = {
//   label1: "#FF5733",
//   label2: "#33FF57",
//   label3: "#3357FF",
// };

// export const iconMap = {
//   label1: "icon1.png",
//   label2: "icon2.png",
//   label3: "icon3.png",
// };
