import moment from 'moment';
import React from 'react';
import nzh from 'nzh/cn';
import { parse, stringify } from 'qs';
import CryptoJS from 'crypto-js';
import { riskTypes } from './constant';
import { router } from 'umi';

/**
 * Converts linebreaks to html br tags
 */
export function nl2br(str, is_xhtml) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = is_xhtml || typeof is_xhtml === 'undefined' ? '<br />' : '<br>';
  return `${str}`.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
}
export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

export function getTimeDistance(type) {
  const now = new Date();
  const oneDay = 1000 * 60 * 60 * 24;

  if (type === 'today') {
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    return [moment(now), moment(now.getTime() + (oneDay - 1000))];
  }

  if (type === 'week') {
    let day = now.getDay();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }

    const beginTime = now.getTime() - day * oneDay;

    return [moment(beginTime), moment(beginTime + (7 * oneDay - 1000))];
  }

  if (type === 'month') {
    const year = now.getFullYear();
    const month = now.getMonth();
    const nextDate = moment(now).add(1, 'months');
    const nextYear = nextDate.year();
    const nextMonth = nextDate.month();

    return [
      moment(`${year}-${fixedZero(month + 1)}-01 00:00:00`),
      moment(moment(`${nextYear}-${fixedZero(nextMonth + 1)}-01 00:00:00`).valueOf() - 1000),
    ];
  }

  const year = now.getFullYear();
  return [moment(`${year}-01-01 00:00:00`), moment(`${year}-12-31 23:59:59`)];
}

export function getPlainNode(nodeList, parentPath = '') {
  const arr = [];
  nodeList.forEach(node => {
    const item = node;
    item.path = `${parentPath}/${item.path || ''}`.replace(/\/+/g, '/');
    item.exact = true;
    if (item.children && !item.component) {
      arr.push(...getPlainNode(item.children, item.path));
    } else {
      if (item.children && item.component) {
        item.exact = false;
      }
      arr.push(item);
    }
  });
  return arr;
}

export function digitUppercase(n) {
  return nzh.toMoney(n);
}

function getRelation(str1, str2) {
  if (str1 === str2) {
    console.warn('Two path are equal!'); // eslint-disable-line
  }
  const arr1 = str1.split('/');
  const arr2 = str2.split('/');
  if (arr2.every((item, index) => item === arr1[index])) {
    return 1;
  }
  if (arr1.every((item, index) => item === arr2[index])) {
    return 2;
  }
  return 3;
}

function getRenderArr(routes) {
  let renderArr = [];
  renderArr.push(routes[0]);
  for (let i = 1; i < routes.length; i += 1) {
    // 去重
    renderArr = renderArr.filter(item => getRelation(item, routes[i]) !== 1);
    // 是否包含
    const isAdd = renderArr.every(item => getRelation(item, routes[i]) === 3);
    if (isAdd) {
      renderArr.push(routes[i]);
    }
  }
  return renderArr;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    routePath => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map(item => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = renderArr.map(item => {
    const exact = !routes.some(route => route !== item && getRelation(route, item) === 1);
    return {
      exact,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

export function getPageQuery() {
  return parse(window.location.href.split('?')[1]);
}

export function getQueryPath(path = '', query = {}) {
  const search = stringify(query);
  if (search.length) {
    return `${path}?${search}`;
  }
  return path;
}

/* eslint no-useless-escape:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export function isUrl(path) {
  return reg.test(path);
}

export function formatWan(val) {
  const v = val * 1;
  if (!v) return '';

  let result = val;
  if (val > 10000) {
    result = Math.floor(val / 10000);
    result = (
      <span>
        {result}
        <span
          style={{
            position: 'relative',
            top: -2,
            fontSize: 14,
            fontStyle: 'normal',
            marginLeft: 2,
          }}
        >
          万
        </span>
      </span>
    );
  }
  return result;
}

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export function isAntdPro() {
  return window.location.hostname === 'preview.pro.ant.design';
}

export const importCDN = (url, name) =>
  new Promise(resolve => {
    const dom = document.createElement('script');
    dom.src = url;
    dom.type = 'text/javascript';
    dom.onload = () => {
      resolve(window[name]);
    };
    document.head.appendChild(dom);
  });

/**
 * Group array by key
 * @param key {string or array} provide array for sub key e.g type.cat will be [type, cat]
 * e.g groupBy([{ type: a }, { type: c }, { type: a }], 'type')
 * e.g2 groupBy([{ type: { cat: a } }, { type: { cat: c } }, { type: { cat: a } }], [type, cat])
 */
export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    // (rv[x[key]] = rv[x[key]] || []).push(x);
    if (typeof key === 'object') (rv[x[key[0]][key[1]]] = rv[x[key[0]][key[1]]] || []).push(x);
    else (rv[x[key]] = rv[x[key]] || []).push(x);

    return rv;
  }, {});
};

export const truncateString = (str, num) => {
  if (str?.length > num) {
    return `${str.slice(0, num)}...`;
  } else {
    return str;
  }
};

export const getTitleOfCard = (title, category) => {
  if (category === 'malware') return `${title} found`;
  else return title;
};

export const formatBadges = text => {
  const string = text;
  text = text.toLowerCase();

  if (text === 'osvulnerabilities' || text === 'os vulnerabilities') return 'OS Vulnerabilities';
  if (text === 'thirdpartyrisks') return 'Third Party Risks';

  if (text === 'iammisconfigurations' || text === 'iam misconfigurations')
    return 'IAM Misconfigurations';

  if (text === 'dataatrisk' || text === 'data at risk') return 'Data At Risk';
  if (text === 'iam' || text === 'i a m') return 'IAM';

  if (text === 'lateral movement' || text === 'lateralmovement') return 'Lateral Movement';

  if (text === 'neglectedassets' || text === 'neglected assets') return 'Neglected Assets';

  if (text === 'aws') return 'AWS';
  if (text === 'gcp') return 'GCP';

  const convertedString = string
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between camelCase words
    .replace(/\b\w/g, match => match.toUpperCase()); // Capitalize the first letter of each word

  return convertedString;
};

export const formatDateAndTime = (date, format = 'h:mm a, D MMM YYYY') => {
  if (moment(date).isValid()) {
    return {
      relativeTime: moment(date).fromNow(),
      exactTime: moment(date).format(format),
    };
  }

  return {
    relativeTime: 'N/A',
    exactTime: 'N/A',
  };
};

export const reformatDateString = dateString => {
  const [day, month, year, time] = dateString.split(/[\s-:]+/);
  return `${year}-${month}-${day}T${time}`;
};

export const decryptAES = encryptedBase64 => {
  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, REACT_APP_SECRET);
  if (decrypted) {
    try {
      const str = decrypted.toString(CryptoJS.enc.Utf8);
      if (str.length > 0) {
        return str;
      } else {
        return 'error 1';
      }
    } catch (e) {
      return 'error 2';
    }
  }
  return 'error 3';
};

export const encryptAES = text => {
  return CryptoJS.AES.encrypt(text, REACT_APP_SECRET).toString();
};

// Redirect the url to the next tab
export const dynamicallyCreateAnchorAndNavigate = url => {
  let anchorElement = document.createElement('a');
  anchorElement.href = url;
  anchorElement.target = '_blank';
  document.body.appendChild(anchorElement);
  anchorElement.click();
  document.body.removeChild(anchorElement);
};

export const scrollToTop = () => {
  // smooth scroll to top
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const compareStrings = (string1, string2) => {
  if (typeof string1 !== 'string' || typeof string2 !== 'string') {
    return false;
  }

  // Convert both strings to lowercase before comparison
  const lowerString1 = string1.toLowerCase();
  const lowerString2 = string2.toLowerCase();

  // Check if the lowercase strings are equal
  return lowerString1 === lowerString2;
};

export const isSuppressionDisabled = record =>
  record.hasOwnProperty('cloudXRayRisks') ||
  riskTypes.cxrRiskNames.includes(record.category) ||
  compareStrings(record?.type, riskTypes.osVulnerabilities) ||
  compareStrings(record?.type, riskTypes.malware) ||
  compareStrings(record?.riskType, riskTypes.osVulnerabilities) ||
  compareStrings(record?.riskType, riskTypes.malware);

// * Def --> Function to redirect to screen with given or provided filters,
// ? Params
// ? orgId --> current orgId
// ? appliedFiltersToBe --> appliedFiltersToBe are the filters to be applied when redirect to screen
// ? defaultFilters --> defaultFilters of the screen means basic filters
// ? screenRoute --> screenName

export const redirectToScreen = (orgId, appliedFiltersToBe, defaultFilters, screenRoute) => {
  // Merge applied filters with default filters
  const mergedFilters = { ...defaultFilters, ...appliedFiltersToBe };

  // Construct an array of filter objects based on merged filters
  const paramsData = Object.keys(mergedFilters).map(type => {
    return {
      type,
      value: mergedFilters[type],
    };
  });

  // Convert paramsData to JSON
  const routeParamsData = JSON.stringify(paramsData);

  // Construct the URL with the routeParamsData
  const url = `/monitoring/${screenRoute}?org=${orgId}&${screenRoute}Filters=${routeParamsData}&dType=`;

  // Navigate to the screen
  router.push(url);
};

// for security groups
export const getTitle = name => {
  name = name.toLowerCase();
  const names = {
    'disaster recovery': {
      name: 'Disaster Recovery',
    },
    'encryption in motion': {
      name: 'Encryption in Motion',
    },
    'encryption at rest': {
      name: 'Encryption at Rest',
    },
    'encryption in transit': {
      name: 'Encryption in Transit',
    },
    logging: {
      name: 'Logging',
    },
    monitoring: {
      name: 'Monitoring',
    },
    security: {
      name: 'Security',
    },
    iam: {
      name: 'IAM',
    },
    networking: {
      name: 'Networking',
    },
    performance: {
      name: 'Performance',
    },
  };

  if (names.hasOwnProperty(name)) {
    return names[name].name;
  } else {
    return 'N/A';
  }
};

export const formatValue = value => {
  if (value === undefined || value === null) return 'N/A';
  else if (value === 0) return 0;
  else return value;
};

// Generates a file name based on the current date and time
export const generateCSVFileName = fileName => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now
    .getDate()
    .toString()
    .padStart(2, '0');
  const hour = now
    .getHours()
    .toString()
    .padStart(2, '0');
  const minute = now
    .getMinutes()
    .toString()
    .padStart(2, '0');

  const dateAndTime = `${year}${month}${day}-${hour}${minute}`;
  return `${fileName} ${dateAndTime}.csv`;
};

// Utility function to transform role names
export const formatRoleNames = roleNames => {
  return Object.keys(roleNames).reduce((acc, key) => {
    acc[key] = roleNames[key]
      .trim()
      .replace(/\s+/g, '_')
      .toLowerCase();
    return acc;
  }, {});
};

// utility function to get signed url for gcp onboarding scripts
export const getSignedUrl = (type, provider, dispatch, setIsDisabled) => {
  dispatch({
    type: 'cloudAccount/generateUrl',
    payload: {
      type: type,
      provider: provider,
    },
  });

  setIsDisabled(true);
  setTimeout(() => {
    setIsDisabled(false); // Re-enable the button after 15 minutes
  }, 900000); // 900000 ms = 15 minutes
};
