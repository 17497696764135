export default {
  firebase: {
    apiKey: 'AIzaSyATIRZdnG3AIXOJvLz334TNpZ293AavlC4',
    authDomain: 'temp-cloudnosys.firebaseapp.com',
    // databaseURL: 'temp-cloudnosys.firebaseio.com',
    databaseURL: 'https://temp-cloudnosys-default-rtdb.firebaseio.com/',
    projectId: 'temp-cloudnosys',
    storageBucket: 'temp-cloudnosys.appspot.com',
    messagingSenderId: '891320166205',
    appId: '1:891320166205:web:9168cadde90f1bcd990754',
  },
  api: {
    explorer: 'https://us-central1-temp-cloudnosys.cloudfunctions.net/explorer',
    playbooks: 'https://temp-cloudnosys.web.app/runPlaybook',
  },
  masterOrg: 'master-cloudnosys_5aj5e',
};
