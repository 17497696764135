import { notification } from 'antd';
import { firebase } from '@/utils/firebase';
import { firestore, functions } from '../utils/firebase';
import axios from 'axios';

/**
 * @description - This is function will remove deleted cloud accounts from access control document.
 * @param {Object} payload - payload includes organizationId and deleted cloud accountId
 * @returns
 */
export const removeAccountFromAccessControl = async payload => {
  try {
    //get all documents of access control
    let accessControlCollection = await firestore
      .collection('Organizations')
      .doc(payload.params.orgId)
      .collection('accessControl')
      .get();

    let allAccessControlDocuments = accessControlCollection.docs;

    //iterate on each document
    allAccessControlDocuments.forEach(async doc => {
      const eachAccessControl = doc.data();
      if (eachAccessControl.hasOwnProperty('cloudAccounts')) {
        let cloudAccounts = eachAccessControl?.cloudAccounts;

        //removing deleted account
        let filteredCloudAccounts = cloudAccounts?.filter(
          eachAccount => eachAccount?.id !== payload?.params?.id
        );
        let eachDocId = doc.ref.id;

        // if deleted account exist then it will update accessControl document
        if (cloudAccounts?.length !== filteredCloudAccounts?.length) {
          await firestore
            .collection('Organizations')
            .doc(payload.params.orgId)
            .collection('accessControl')
            .doc(eachDocId)
            .update({
              cloudAccounts: filteredCloudAccounts,
            });
        }
      }
    });
  } catch (error) {
    console.error('error in removing cloudAccount from accessControl: ', error);
    return false;
  }
};

/**
 * @description - This function will remove the deleted scopeReports which has deleted cloudAccounts id.
 * @param {Object} payload - payload includes organizationId and deleted cloud accountId
 * @returns
 */

export const removeScopeReport = async payload => {
  try {
    // Get all documents of scopeReport
    let scopeReportCollection = await firestore
      .collection('Organizations')
      .doc(payload.params.orgId)
      .collection('scopeReport')
      .get();

    let allScopeReportDocuments = scopeReportCollection.docs;

    // Iterate over each document with async/await
    for (const doc of allScopeReportDocuments) {
      const eachScopeReport = doc.data();

      if (eachScopeReport.hasOwnProperty('cloudAccounts')) {
        let cloudAccounts = eachScopeReport.cloudAccounts;

        // Check if the cloudAccount exists in the document
        let hasCloudAccount = cloudAccounts.some(eachAccount => eachAccount === payload.params.id);

        let eachDocId = doc.ref.id;

        // If cloudAccount exists, delete the scopeReport document
        if (hasCloudAccount) {
          await firestore
            .collection('Organizations')
            .doc(payload.params.orgId)
            .collection('scopeReport')
            .doc(eachDocId)
            .delete();
        }
      }
    }

    return true; // Success
  } catch (error) {
    console.error('Error in deleting scopeReport: ', error);
    return false; // Failure
  }
};

/**
 * @description - Callback function for adding cloud account - RiskModule.addCloudAccount
 * @param {*} params - cloud account info
 * @returns {Object} - response from the callback
 */
export const addCloudAccount = params => {
  return functions.httpsCallable('RiskModule-addCloudAccount')(params);
};

/**
 * @description - Callback function for updating billing beofre deleting cloud account - RiskModule.updateBillingBeforeDeletion
 * @param {*} params - cloud account info
 * @returns {Object} - response from the callback
 */
export const updateBillingBeforeDeletion = params => {
  return functions.httpsCallable('RiskModule-updateBillingBeforeDeletion')(params);
};

/**
 * @description - Function for testing cloud account
 * @params {Object} - { roleArn, type }
 * @returns {Object} - { status: 200, message: 'ok'}
 */
export const testCloudAccountApis = params => {
  return functions.httpsCallable('Reports-CheckHealth')(params);
};

/**
 * @description - Function for testing gcp cloud account
 * @params {Object} - { serviceAccount, type }
 * @returns {Object} - { status: 200, message: 'ok'}
 */
export const testGCPCloudAccountApis = params => {
  return functions.httpsCallable('Reports-CheckHealth')(params);
};

/**
 * @description - Function for saving results of testConnection in the DB
 * @params {Object} - { orgId, cloudAccountId, testConnection }
 * @returns {Object} - { status: 200, message: 'Successfully Saved'}
 */
export const saveTestApis = params => {
  return functions.httpsCallable('CloudXRay-saveTestApis')(params);
};

/**
 * @description - Function to generate url for cft and stackset
 * @params {Object} - { externalId: randomString, type:deployStackset/deployCft, }
 * @returns url- String
 */
export const generateUrl = async params => {
  try {
    let buttonName = params.type === 'rolesDeployment' ? 'deployCft' : 'deployStack';
    let { data } = await functions.httpsCallable('CloudXRay-genrateXRayURL')(params);
    if (data?.statusCode >= 400 && data?.statusCode < 500) {
      notification.error({ message: data.message });
      return;
    }
    if (data) {
      return {
        url: data,
        buttonName,
      };
    }
  } catch (error) {
    console.error('ERROR FROM GENERATE URL', error);
    notification.error({ message: 'An error occurred while generating template URL' });
  }
};

/**
 * @description - Function for testing getDynamicRegions
 * @params {Object} - { regions, regionsArray }
 * @returns {Object} - { status: 200, message: 'ok'}
 */
export const getDynamicRegions = params => {
  return functions.httpsCallable('CloudXRay-getDynamicRegions')(params);
};

// Function to initiate force cleanup
export const initiateForceCleanup = async params => {
  try {
    let { projectId } = firebase.apps[0].options;
    const isValidProjectId =
      projectId === 'cloudnosysbeta' ||
      projectId === 'temp-cloudnosys' ||
      projectId === 'cloudnosys-prod';

    const cloudFunctionsRegion = isValidProjectId ? 'us-central1' : '';
    let url = `https://${cloudFunctionsRegion}-${projectId}.cloudfunctions.net/CloudXRay-cloudXRayReceiver`;
    // let url = `http://localhost:5003/temp-cloudnosys/us-central1/CloudXRay-cloudXRayReceiver`; // local

    const { data } = await axios.post(url, params);
    if (data?.statusCode >= 400 && data?.statusCode < 500) {
      notification.error({ message: 'An error occurred while initiating Force Cleanup' });
    }
    notification.success({ message: 'Force Cleanup initiated successfully' });
    return data;
  } catch (error) {
    console.error('Error while initiating force cleanup: ', error);
    notification.error({ message: 'An error occurred while initiating Force Cleanup' });
    throw error;
  }
};

/**+
 * @description - Function to check if the roles already exist in the aws for the given account
 * @params {Object} - { accountId, externalId, roleArn }
 * @returns {Object} - { status: 200, message: 'ok'}
 */

export const checkRolesExist = params => {
  return functions.httpsCallable('Reports-retrieveRole')(params);
};
